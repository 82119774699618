.footer {
    display: flex;
    justify-content: space-around;
    padding: 50px 10vw 20px 10vw;
    li{
        cursor:pointer;
    }
    .foot-left {
        h2 {
            font-family: PingFang SC;
            font-weight: bold;
            font-size: 18px;
            line-height: 27px;
            margin-bottom: 14px;
        }
        p {
            color: rgb(120, 113, 113);
            font-family: PingFang SC;
            font-size: 14px;
            line-height: 21px;
        }
    }
    .homelogo{
        font-size: 32px;
    };
    .foot-right {
        display: flex;
        flex:1;
        justify-content: space-between;
        gap:1rem;
        ul {
            li {
                margin-bottom: 14px;
                &:first-child {
                    font-family: PingFang SC;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 24px;
                    margin-bottom: 25px;
                }
            }
        }
    }
}
@media screen and (max-width:1080px) {
    .footer {
        padding: 0 5vw;
        .ant-divider-horizontal {
            margin: 8px 0;
        }
        .foot-left {
            display: flex;
            flex-direction: column;

            img {
                width: 80%;
                height: 100%;
            }
        }
        .homelogo{
            font-size: 24px;
        };
        .foot-right {
            ul {
                &:last-child {
                    img {
                        width: 100px;
                    }
                }
                li {
                    margin-bottom: 5px;
                    &:first-child {
                        margin-bottom: 6px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width:820px) {
    .footer {
        display: flex;
        flex-direction: column;
        padding: 0 5vw;
        .ant-divider-horizontal {
            margin: 8px 0;
        }
        .foot-left {
            display: flex;
            flex-direction: column;

            img {
                width: 80%;
                height: 100%;
            }
        }
        .foot-right {
            display: flex;
            flex-direction: column;
            ul {
                &:last-child {
                    img {
                        width: 100px;
                    }
                }
                li {
                    margin-bottom: 5px;
                    &:first-child {
                        margin-bottom: 6px;
                    }
                }
            }
        }
    }
}

ol,
ul,
li {
    padding-left: 0;
    list-style: none;
}
